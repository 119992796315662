// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import ChatWidget from './components/ChatWidget';

// Configuración por defecto para el widget cuando se usa como aplicación principal
const defaultConfig = {
  apiUrl: 'https://back-webchat-934866038204.us-central1.run.app/api',
  widgetUrl: 'https://webchat2-934866038204.us-central1.run.app',
  primaryColor: '#2563eb',
  position: 'right',
  botName: 'UltraDeep Assistant'
};

// Función para renderizar el widget cuando se usa como widget embebido
window.renderChatWidget = function(container, config) {
  const root = createRoot(container);
  root.render(
    <ChatWidget config={config} />
  );
};

// Renderizar la aplicación principal
const rootElement = document.getElementById('root');
if (rootElement) {
  const root = createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <App>
        <ChatWidget config={defaultConfig} />
      </App>
    </React.StrictMode>
  );
}