import React, { useState } from 'react';

const StatsSection = () => {
  const [stats, setStats] = useState({
    totalMessages: 0,
    activeChats: 0,
    lastUsed: '',
    monthlyUsage: 0,
    avgResponseTime: '2s',
    successRate: 95,
    dailyUsers: 0,
    totalUsers: 0
  });

  return (
    <div className="bg-white rounded-lg shadow p-6 mb-6">
      <h2 className="text-xl font-semibold mb-4">Estadísticas del Chat</h2>
      
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {/* Total de Mensajes */}
        <div className="bg-blue-50 rounded-lg p-4">
          <h3 className="text-sm font-medium text-blue-600 mb-1">Total Mensajes</h3>
          <p className="text-2xl font-bold text-blue-900">{stats.totalMessages}</p>
        </div>

        {/* Chats Activos */}
        <div className="bg-green-50 rounded-lg p-4">
          <h3 className="text-sm font-medium text-green-600 mb-1">Chats Activos</h3>
          <p className="text-2xl font-bold text-green-900">{stats.activeChats}</p>
        </div>

        {/* Tasa de Éxito */}
        <div className="bg-indigo-50 rounded-lg p-4">
          <h3 className="text-sm font-medium text-indigo-600 mb-1">Tasa de Éxito</h3>
          <p className="text-2xl font-bold text-indigo-900">{stats.successRate}%</p>
        </div>

        {/* Tiempo de Respuesta */}
        <div className="bg-purple-50 rounded-lg p-4">
          <h3 className="text-sm font-medium text-purple-600 mb-1">Tiempo Respuesta</h3>
          <p className="text-2xl font-bold text-purple-900">{stats.avgResponseTime}</p>
        </div>

        {/* Usuarios Diarios */}
        <div className="bg-pink-50 rounded-lg p-4">
          <h3 className="text-sm font-medium text-pink-600 mb-1">Usuarios Hoy</h3>
          <p className="text-2xl font-bold text-pink-900">{stats.dailyUsers}</p>
        </div>

        {/* Usuarios Totales */}
        <div className="bg-yellow-50 rounded-lg p-4">
          <h3 className="text-sm font-medium text-yellow-600 mb-1">Usuarios Totales</h3>
          <p className="text-2xl font-bold text-yellow-900">{stats.totalUsers}</p>
        </div>

        {/* Uso Mensual */}
        <div className="bg-red-50 rounded-lg p-4">
          <h3 className="text-sm font-medium text-red-600 mb-1">Uso Mensual</h3>
          <p className="text-2xl font-bold text-red-900">{stats.monthlyUsage}%</p>
        </div>

        {/* Último Uso */}
        <div className="bg-gray-50 rounded-lg p-4">
          <h3 className="text-sm font-medium text-gray-600 mb-1">Último Uso</h3>
          <p className="text-sm font-bold text-gray-900">{stats.lastUsed || 'No hay datos'}</p>
        </div>
      </div>

      {/* Gráfico de uso semanal */}
      <div className="mt-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Uso Semanal</h3>
        <div className="h-64 bg-gray-50 rounded-lg p-4">
          <div className="flex items-end justify-between h-full">
            {['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'].map((day, index) => (
              <div key={day} className="flex flex-col items-center w-1/7">
                <div 
                  className="bg-blue-500 w-8" 
                  style={{ 
                    height: `${Math.random() * 100}%`,
                    minHeight: '20px'
                  }}
                ></div>
                <span className="text-xs mt-2">{day}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsSection;