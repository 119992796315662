import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import StatsSection from './StatsSection'; // Agregar esta importación


const InstallationSection = ({ apiKey = "DEMO-API-KEY" }) => {
  const installCode = `
<!-- Agregar antes del cierre de </body> -->
<script>
  window.ultraDeepConfig = {
    apiKey: '${apiKey}',
    apiUrl: 'https://back-webchat-934866038204.us-central1.run.app/api',
    widgetUrl: 'https://webchat2-934866038204.us-central1.run.app',
    primaryColor: '#2563eb',
    position: 'right',
    botName: 'UltraDeep Assistant'
  };
</script>
<script src="https://webchat2-934866038204.us-central1.run.app/widget-loader.js" async></script>
  `.trim();

  const handleCopy = () => {
    navigator.clipboard.writeText(installCode);
    toast.success('Código copiado al portapapeles');
  };

  return (
    <div className="bg-white rounded-lg shadow p-6 mb-6">
      <h2 className="text-xl font-semibold mb-4">Código de Instalación</h2>
      <p className="mb-4 text-gray-600">
        Para integrar el chat en tu sitio web, copia y pega este código antes del cierre del tag <code>&lt;/body&gt;</code>
      </p>
      <div className="relative">
        <pre className="bg-gray-50 p-4 rounded-lg overflow-x-auto text-sm">
          <code>{installCode}</code>
        </pre>
        <button
          onClick={handleCopy}
          className="absolute top-2 right-2 bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 text-sm"
        >
          Copiar Código
        </button>
      </div>
      <div className="mt-4 bg-blue-50 p-4 rounded-lg">
        <h3 className="font-medium text-blue-800 mb-2">Información Importante</h3>
        <ul className="list-disc list-inside text-sm text-blue-700 space-y-1">
          <li>Este código debe colocarse antes del cierre del body en tu HTML</li>
          <li>La API Key es única para tu cuenta - no la compartas</li>
          <li>Los cambios en la configuración se reflejarán automáticamente</li>
          <li>El widget se adaptará automáticamente al tema de tu sitio</li>
        </ul>
      </div>
    </div>
  );
};

const AdminDashboard = ({ currentConfig, onConfigUpdate }) => {
  const [settings, setSettings] = useState(currentConfig);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentTab, setCurrentTab] = useState('config'); // 'config' o 'installation'

  useEffect(() => {
    setSettings(currentConfig);
  }, [currentConfig]);

  const handleSettingChange = (key, value) => {
    const newSettings = { ...settings, [key]: value };
    setSettings(newSettings);
  };

  const handleSaveSettings = async () => {
    try {
      setLoading(true);
      setError(null);
      await new Promise(resolve => setTimeout(resolve, 500));
      onConfigUpdate(settings);
      toast.success('¡Configuración actualizada con éxito!');
    } catch (err) {
      setError('Error al guardar la configuración');
      toast.error('Error al guardar los cambios');
    } finally {
      setLoading(false);
    }
  };

    return (
      <div className="min-h-screen bg-blue-500 p-4">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-2xl font-bold text-white text-center mb-6">Panel de Control - Chat Widget</h1>
  
          {/* Tabs de navegación actualizados */}
          <div className="bg-white rounded-lg shadow mb-6">
            <div className="flex border-b">
              <button
                onClick={() => setCurrentTab('stats')}
                className={`px-6 py-3 text-sm font-medium ${
                  currentTab === 'stats'
                    ? 'border-b-2 border-blue-500 text-blue-600'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
              >
                Estadísticas
              </button>
              <button
                onClick={() => setCurrentTab('config')}
                className={`px-6 py-3 text-sm font-medium ${
                  currentTab === 'config'
                    ? 'border-b-2 border-blue-500 text-blue-600'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
              >
                Configuración
              </button>
              <button
                onClick={() => setCurrentTab('installation')}
                className={`px-6 py-3 text-sm font-medium ${
                  currentTab === 'installation'
                    ? 'border-b-2 border-blue-500 text-blue-600'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
              >
                Instalación
              </button>
            </div>
          </div>
  
          {/* Contenido basado en la pestaña seleccionada */}
          {currentTab === 'stats' ? (
            <StatsSection />
          ) : currentTab === 'installation' ? (
            <InstallationSection apiKey="TU-API-KEY-AQUÍ" />
          ) : (
          <>
            {/* Configuración Básica */}
            <div className="bg-white rounded-lg shadow p-6 mb-6">
              <h2 className="text-xl font-semibold mb-4">Configuración Básica</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Nombre del Bot
                  </label>
                  <input
                    type="text"
                    value={settings.botName}
                    onChange={(e) => handleSettingChange('botName', e.target.value)}
                    className="w-full p-2 border rounded-md"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Mensaje de Bienvenida
                  </label>
                  <input
                    type="text"
                    value={settings.welcomeMessage}
                    onChange={(e) => handleSettingChange('welcomeMessage', e.target.value)}
                    className="w-full p-2 border rounded-md"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Texto del Encabezado
                  </label>
                  <input
                    type="text"
                    value={settings.headerText}
                    onChange={(e) => handleSettingChange('headerText', e.target.value)}
                    className="w-full p-2 border rounded-md"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Texto del Botón
                  </label>
                  <input
                    type="text"
                    value={settings.buttonText}
                    onChange={(e) => handleSettingChange('buttonText', e.target.value)}
                    className="w-full p-2 border rounded-md"
                  />
                </div>
              </div>
            </div>

            {/* Apariencia */}
            <div className="bg-white rounded-lg shadow p-6 mb-6">
              <h2 className="text-xl font-semibold mb-4">Apariencia</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Color Principal
                  </label>
                  <div className="flex items-center">
                    <input
                      type="color"
                      value={settings.primaryColor}
                      onChange={(e) => handleSettingChange('primaryColor', e.target.value)}
                      className="w-8 h-8 mr-2"
                    />
                    <input
                      type="text"
                      value={settings.primaryColor}
                      onChange={(e) => handleSettingChange('primaryColor', e.target.value)}
                      className="flex-1 p-2 border rounded-md"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Estilo del Botón
                  </label>
                  <select
                    value={settings.buttonStyle}
                    onChange={(e) => handleSettingChange('buttonStyle', e.target.value)}
                    className="w-full p-2 border rounded-md"
                  >
                    <option value="round">Redondo</option>
                    <option value="square">Cuadrado</option>
                    <option value="pill">Píldora</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Tamaño de Fuente
                  </label>
                  <select
                    value={settings.fontSize}
                    onChange={(e) => handleSettingChange('fontSize', e.target.value)}
                    className="w-full p-2 border rounded-md"
                  >
                    <option value="small">Pequeño</option>
                    <option value="medium">Mediano</option>
                    <option value="large">Grande</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Posición
                  </label>
                  <select
                    value={settings.position}
                    onChange={(e) => handleSettingChange('position', e.target.value)}
                    className="w-full p-2 border rounded-md"
                  >
                    <option value="right">Derecha</option>
                    <option value="left">Izquierda</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Idioma
                  </label>
                  <select
                    value={settings.language}
                    onChange={(e) => handleSettingChange('language', e.target.value)}
                    className="w-full p-2 border rounded-md"
                  >
                    <option value="es">Español</option>
                    <option value="en">Inglés</option>
                    <option value="pt">Portugués</option>
                  </select>
                </div>
              </div>
            </div>

            {/* Opciones Adicionales */}
            <div className="bg-white rounded-lg shadow p-6 mb-6">
              <h2 className="text-xl font-semibold mb-4">Opciones Adicionales</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={settings.showTimestamp}
                    onChange={(e) => handleSettingChange('showTimestamp', e.target.checked)}
                    className="h-4 w-4 text-blue-600"
                  />
                  <label className="ml-2 text-sm text-gray-700">
                    Mostrar hora en mensajes
                  </label>
                </div>

                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={settings.soundEnabled}
                    onChange={(e) => handleSettingChange('soundEnabled', e.target.checked)}
                    className="h-4 w-4 text-blue-600"
                  />
                  <label className="ml-2 text-sm text-gray-700">
                    Habilitar sonidos
                  </label>
                </div>

                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={settings.avatarEnabled}
                    onChange={(e) => handleSettingChange('avatarEnabled', e.target.checked)}
                    className="h-4 w-4 text-blue-600"
                  />
                  <label className="ml-2 text-sm text-gray-700">
                    Mostrar avatar del bot
                  </label>
                </div>
              </div>
            </div>

            {/* Botones de Acción */}
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setSettings(currentConfig)}
                className="px-4 py-2 text-white bg-gray-500 rounded-md hover:bg-gray-600"
                disabled={loading}
              >
                Cancelar Cambios
              </button>
              <button
                onClick={handleSaveSettings}
                disabled={loading}
                className="px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700"
              >
                {loading ? 'Guardando...' : 'Guardar Cambios'}
              </button>
            </div>
          </>
        )}

        {error && (
          <div className="mt-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded-md">
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;