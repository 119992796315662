// src/App.js
import React, { useState, useEffect } from 'react';
import AdminDashboard from './components/AdminDashboard';
import ChatWidget from './components/ChatWidget';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const [chatConfig, setChatConfig] = useState({
    // Configuración de la API y URLs
    apiUrl: 'https://back-webchat-934866038204.us-central1.run.app',
    widgetUrl: 'https://webchat2-934866038204.us-central1.run.app',
    
    // Configuración de la apariencia
    welcomeMessage: '¡Hola! Soy Max, asistente virtual de Ultra Deep Tech.',
    theme: 'blue',
    position: 'right',
    botName: 'Max',
    headerText: 'Ultra Deep Tech Chat',
    buttonText: 'Chat',
    primaryColor: '#2563eb',
    fontSize: 'medium',
    showTimestamp: true,
    soundEnabled: true,
    language: 'es',
    buttonStyle: 'round',
    avatarEnabled: true,

    // Credenciales por defecto
    credentials: {
      email: 'widget@caresse.com',
      password: 'CaresseWidget2024!'
    }
  });

  const [isConfigLoaded, setIsConfigLoaded] = useState(false);

  // Cargar configuración guardada al iniciar
  useEffect(() => {
    const loadSavedConfig = () => {
      try {
        const savedConfig = localStorage.getItem('chatConfig');
        if (savedConfig) {
          setChatConfig(prev => ({
            ...prev,
            ...JSON.parse(savedConfig)
          }));
        }
      } catch (error) {
        console.error('Error loading saved config:', error);
      } finally {
        setIsConfigLoaded(true);
      }
    };

    loadSavedConfig();
  }, []);

  // Función para actualizar la configuración
  const handleConfigUpdate = (newConfig) => {
    try {
      // Guardar en localStorage
      localStorage.setItem('chatConfig', JSON.stringify(newConfig));
      
      // Actualizar estado
      setChatConfig(newConfig);

      // Aquí podrías agregar una llamada a la API para guardar en backend
      // saveConfigToBackend(newConfig);
    } catch (error) {
      console.error('Error saving config:', error);
    }
  };

  // Función para reiniciar la configuración
  const handleResetConfig = () => {
    localStorage.removeItem('chatConfig');
    window.location.reload();
  };

  if (!isConfigLoaded) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-blue-500">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto"></div>
          <p className="mt-4 text-gray-600">Cargando configuración...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="relative">
      <AdminDashboard 
        currentConfig={chatConfig} 
        onConfigUpdate={handleConfigUpdate}
        onResetConfig={handleResetConfig}
      />
      <ChatWidget 
        config={chatConfig}
      />
      <ToastContainer 
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default App;