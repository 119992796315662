import React, { useState, useRef, useEffect, useCallback } from 'react';

const ChatWidget = ({ config }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);
  const messagesEndRef = useRef(null);

  const {
    apiUrl = 'https://back-webchat-934866038204.us-central1.run.app',
    welcomeMessage = '¡Hola! Soy Max, asistente virtual.',
    position = 'right',
    headerText = 'Chat',
    buttonText = 'Chat',
    primaryColor = '#2563eb',
    fontSize = 'medium',
    showTimestamp = true,
    soundEnabled = true,
    buttonStyle = 'round',
    avatarEnabled = true
  } = config || {};

  const login = useCallback(async () => {
    try {
      setError(null);
      const loginUrl = `${apiUrl}/api/widget-auth/login`;
      console.log('Intentando login en:', loginUrl);
      
      const response = await fetch(loginUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          email: 'widget@caresse.com',
          password: 'CaresseWidget2024!'
        })
      });

      if (!response.ok) {
        const errorMessage = `Error de autenticación: ${response.status}`;
        console.error(errorMessage);
        setError(errorMessage);
        throw new Error(errorMessage);
      }

      const data = await response.json();
      console.log('Login exitoso');
      setToken(data.access_token);
      setError(null);
    } catch (error) {
      const errorMessage = error.message || 'Error al intentar conectar con el servidor';
      console.error('Error de login:', errorMessage);
      setError(errorMessage);
      setToken(null);
    }
  }, [apiUrl]);

  useEffect(() => {
    login();
  }, [login]);

  const handleSend = async () => {
    if (!inputMessage.trim() || !token) return;

    const newMessage = {
      text: inputMessage.trim(),
      sender: 'user',
      timestamp: new Date().toLocaleTimeString()
    };

    setMessages(prev => [...prev, newMessage]);
    setInputMessage('');
    setIsLoading(true);
    setError(null);

    try {
      const chatUrl = `${apiUrl}/api/texttospeech/widget/chat`;
      const response = await fetch(chatUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          query: inputMessage.trim(),
          type: "chat"
        })
      });

      if (!response.ok) {
        if (response.status === 401) {
          await login();
          throw new Error('Por favor, intenta nuevamente.');
        }
        throw new Error(`Error en la comunicación: ${response.status}`);
      }

      const botResponse = {
        text: await response.text(),
        sender: 'bot',
        timestamp: new Date().toLocaleTimeString()
      };
      
      if (soundEnabled) {
        new Audio('/notification.mp3').play().catch(() => {});
      }
      
      setMessages(prev => [...prev, botResponse]);
    } catch (error) {
      console.error('Error:', error);
      setError(error.message);
      setMessages(prev => [...prev, {
        text: "Lo siento, ocurrió un error en la comunicación. Por favor, intenta nuevamente.",
        sender: 'bot',
        timestamp: new Date().toLocaleTimeString()
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  const getButtonClasses = () => {
    const baseClasses = `fixed ${position}-4 bottom-4 z-50 text-white p-4 shadow-lg`;
    const styleClasses = {
      round: 'rounded-full',
      square: 'rounded-lg',
      pill: 'rounded-full px-6'
    };
    return `${baseClasses} ${styleClasses[buttonStyle]}`;
  };

  const getFontSize = () => {
    const sizes = {
      small: 'text-sm',
      medium: 'text-base',
      large: 'text-lg'
    };
    return sizes[fontSize];
  };

  return (
    <div>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={getButtonClasses()}
        style={{ backgroundColor: primaryColor }}
      >
        {isOpen ? (
          <span className="text-xl">×</span>
        ) : (
          <span className={getFontSize()}>{buttonText}</span>
        )}
      </button>

      {isOpen && (
        <div className={`fixed ${position}-4 bottom-16 w-80 h-96 bg-white rounded-lg shadow-xl flex flex-col z-50`}>
          <div className="rounded-t-lg p-4" style={{ backgroundColor: primaryColor }}>
            <h3 className={`font-medium text-white ${getFontSize()}`}>{headerText}</h3>
          </div>

          <div className="flex-1 p-4 overflow-y-auto">
            {error && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded mb-4">
                {error}
              </div>
            )}
            
            {messages.length === 0 && !error && (
              <div className="text-center text-gray-500 mt-4">
                {welcomeMessage}
              </div>
            )}
            
            {messages.map((message, index) => (
              <div
                key={index}
                className={`mb-4 ${message.sender === 'user' ? 'text-right' : 'text-left'}`}
              >
                <div className="flex items-start">
                  {message.sender === 'bot' && avatarEnabled && (
                    <div className="w-8 h-8 rounded-full bg-gray-200 mr-2 flex-shrink-0" />
                  )}
                  <div
                    className={`inline-block p-2 rounded-lg ${
                      message.sender === 'user'
                        ? 'bg-blue-600 text-white'
                        : 'bg-gray-200'
                    }`}
                    style={{ 
                      backgroundColor: message.sender === 'user' ? primaryColor : undefined 
                    }}
                  >
                    <p className={getFontSize()}>{message.text}</p>
                    {showTimestamp && (
                      <span className="text-xs opacity-75">{message.timestamp}</span>
                    )}
                  </div>
                </div>
              </div>
            ))}
            
            {isLoading && (
              <div className="text-left mb-4">
                <div className="inline-block p-2 rounded-lg bg-gray-200">
                  <div className="flex space-x-2">
                    <div className="w-2 h-2 bg-blue-600 rounded-full animate-bounce"></div>
                    <div className="w-2 h-2 bg-blue-600 rounded-full animate-bounce delay-100"></div>
                    <div className="w-2 h-2 bg-blue-600 rounded-full animate-bounce delay-200"></div>
                  </div>
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>

          <div className="p-4 border-t">
            <div className="flex gap-2">
              <input
                type="text"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                placeholder="Escribe un mensaje..."
                className={`flex-1 p-2 border rounded-lg focus:outline-none focus:ring-2 ${getFontSize()}`}
                style={{ focusRingColor: primaryColor }}
                disabled={!token || isLoading}
              />
              <button
                onClick={handleSend}
                className="px-4 py-2 text-white rounded-lg hover:opacity-90 disabled:opacity-50"
                style={{ backgroundColor: primaryColor }}
                disabled={isLoading || !token || !inputMessage.trim()}
              >
                Enviar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatWidget;